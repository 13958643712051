import { useLocalStorage } from '@vueuse/core';

type LayoutConfigPreset =
    | 'Aura'
    | 'Lara'

type LayoutConfigSurfaceName =
    | 'slate'
    | 'gray'
    | 'zinc'
    | 'neutral'
    | 'stone'
    | 'soho'
    | 'viva'
    | 'ocean'

type LayoutConfigMenuModeValue =
    | 'static'
    | 'overlay'
    | 'horizontal'

type LayoutConfigPrimaryName =
    | 'noir'
    | 'emerald'
    | 'green'
    | 'lime'
    | 'orange'
    | 'amber'
    | 'yellow'
    | 'teal'
    | 'cyan'
    | 'sky'
    | 'blue'
    | 'indigo'
    | 'violet'
    | 'purple'
    | 'fuchsia'
    | 'pink'
    | 'rose'


type LayoutConfig = {
  preset: LayoutConfigPreset
  primary: LayoutConfigPrimaryName
  surface: LayoutConfigSurfaceName|null
  darkTheme: boolean
  menuMode: LayoutConfigMenuModeValue
  menuTheme: 'dark' | 'light'
  topbarTheme: 'dark' | 'light'
}

type LayoutState = {
  staticMenuDesktopInactive: boolean
  overlayMenuActive: boolean
  configSidebarVisible: boolean
  staticMenuMobileActive: boolean
  menuHoverActive: boolean
  rightMenuActive: boolean
  topbarMenuActive: boolean
  sidebarActive: boolean
  activeMenuItem: null|string
  overlaySubmenuActive: boolean
}

const layoutConfig = useLocalStorage<LayoutConfig>('layoutConfig', {
  preset: 'Aura',
  primary: 'emerald',
  surface: null,
  darkTheme: false,
  menuMode: 'static',
  menuTheme: 'dark',
  topbarTheme: 'light'
});

const layoutState = useLocalStorage<LayoutState>('layoutState', {
  staticMenuDesktopInactive: false,
  overlayMenuActive: false,
  configSidebarVisible: false,
  staticMenuMobileActive: false,
  menuHoverActive: false,
  rightMenuActive: false,
  topbarMenuActive: false,
  sidebarActive: false,
  activeMenuItem: null,
  overlaySubmenuActive: false
});

let tabs = ref([]);
const outsideClickListener = ref(null);

export function useLayout() {
    const setPrimary = (value: LayoutConfigPrimaryName) => {
        layoutConfig.value.primary = value;
    };

    const setSurface = (value: LayoutConfigSurfaceName) => {
        layoutConfig.value.surface = value;
    };

    const setPreset = (value: LayoutConfigPreset) => {
        layoutConfig.value.preset = value;
    };

    const setMenuMode = (mode: LayoutConfigMenuModeValue) => {
        layoutConfig.value.menuMode = mode;

        if (mode === 'static') {
            layoutState.value.staticMenuDesktopInactive = false;
        }
    };

    const showConfigSidebar = () => {
        layoutState.value.configSidebarVisible = true;
    };

    const toggleDarkMode = () => {
        if (!document.startViewTransition) {
            executeDarkModeToggle();

            return;
        }

        document.startViewTransition(() => executeDarkModeToggle(event));
    };

    const executeDarkModeToggle = () => {
        layoutConfig.value.darkTheme = !layoutConfig.value.darkTheme;
        layoutConfig.value.menuTheme = isDarkTheme.value ? 'dark' : 'light';
        layoutConfig.value.topbarTheme = isDarkTheme.value ? 'dark' : 'light';

        document.documentElement.classList.toggle('app-dark');
    };

    const setActiveMenuItem = (item) => {
        layoutState.value.activeMenuItem = item.value || item;
    };

    const setMenuStates = (value) => {
        layoutState.value.overlaySubmenuActive = value;
        layoutState.value.menuHoverActive = value;
    };

    const setStaticMenuMobile = () => {
        layoutState.value.staticMenuMobileActive = !layoutState.value.staticMenuMobileActive;
    };

    const watchSidebarActive = () => {
        watch(isSidebarActive, (newVal) => {
            if (newVal) {
                bindOutsideClickListener();
            } else {
                unbindOutsideClickListener();
            }
        });
    };

    const onMenuToggle = () => {
        if (layoutConfig.value.menuMode === 'overlay') {
            layoutState.value.overlayMenuActive = !layoutState.value.overlayMenuActive;
        }

        if (window.innerWidth > 991) {
            layoutState.value.staticMenuDesktopInactive = !layoutState.value.staticMenuDesktopInactive;
        } else {
            layoutState.value.staticMenuMobileActive = !layoutState.value.staticMenuMobileActive;
        }
    };

    const onConfigSidebarToggle = () => {
        if (isSidebarActive.value) {
            resetMenu();
            unbindOutsideClickListener();
        }

        layoutState.value.configSidebarVisible = !layoutState.value.configSidebarVisible;
    };

    const onRightPanelToggle = () => {
        layoutState.value.rightMenuActive = !layoutState.value.rightMenuActive;
    };

    const openTab = (value) => {
        tabs.value = [...tabs.value, value];
    };

    const closeTab = (index) => {
        tabs.value.splice(index, 1);
        tabs.value = [...tabs.value];
    };

    const bindOutsideClickListener = () => {
        if (!outsideClickListener.value) {
            outsideClickListener.value = (event) => {
                if (isOutsideClicked(event)) {
                    resetMenu();
                }
            };
            document.addEventListener('click', outsideClickListener.value);
        }
    };

    const unbindOutsideClickListener = () => {
        if (outsideClickListener.value) {
            document.removeEventListener('click', outsideClickListener.value);
            outsideClickListener.value = null;
        }
    };

    const isOutsideClicked = (event) => {
        const sidebarEl = document.querySelector('.layout-sidebar');
        const topbarDesktopEl = document.querySelector('.layout-menu-button-desktop');
        const topbarMobileEl = document.querySelector('.layout-menu-button-mobile');

        return !(
            sidebarEl?.isSameNode(event.target) ||
            sidebarEl?.contains(event.target) ||
            topbarDesktopEl?.isSameNode(event.target) ||
            topbarDesktopEl?.contains(event.target) ||
            topbarMobileEl?.isSameNode(event.target) ||
            topbarMobileEl?.contains(event.target)
        );
    };

    const resetMenu = () => {
        layoutState.value.overlayMenuActive = false;
        layoutState.value.overlaySubmenuActive = false;
        layoutState.value.staticMenuMobileActive = false;
        layoutState.value.menuHoverActive = false;
        layoutState.value.configSidebarVisible = false;
    };

    const isSidebarActive = computed(() => layoutState.value.overlayMenuActive || layoutState.value.staticMenuMobileActive || layoutState.value.overlaySubmenuActive);

    const isDesktop = computed(() => window.innerWidth > 991);
    const isMobile = computed(() => window.innerWidth <= 991);

    const isHorizontal = computed(() => layoutConfig.value.menuMode === 'horizontal');
    const isOverlay = computed(() => layoutConfig.value.menuMode === 'overlay');

    const isDarkTheme = computed(() => layoutConfig.value.darkTheme);
    const getPrimary = computed(() => layoutConfig.value.primary);
    const getSurface = computed(() => layoutConfig.value.surface);

    return {
        layoutConfig,
        layoutState,
        getPrimary,
        getSurface,
        isDarkTheme,
        setPrimary,
        setSurface,
        setPreset,
        setMenuMode,
        showConfigSidebar,
        toggleDarkMode,
        onMenuToggle,
        setMenuStates,
        setStaticMenuMobile,
        watchSidebarActive,
        isSidebarActive,
        setActiveMenuItem,
        onConfigSidebarToggle,
        onRightPanelToggle,
        isHorizontal,
        isDesktop,
        isMobile,
        isOverlay,
        openTab,
        tabs,
        closeTab,
        unbindOutsideClickListener
    };
}
